/** @jsxImportSource theme-ui */
import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Link from "../../../components/Link";
import { Card } from "theme-ui";
import { Themed } from "@theme-ui/mdx";

const VehicleCard = ({ vehicle }) => {
  const { frontmatter, fields } = vehicle;
  const { slug } = fields;
  const { title, active, dispatcherName, image } = frontmatter;

  const isVehicleActive = active.to === null;
  let additionalInfo = isVehicleActive ? (
    <Themed.p>seit {active.from} im Einsatz</Themed.p>
  ) : (
    <Themed.p>
      {" "}
      <span>
        (Einsatzzeitraum: {active.from}-{active.to})
      </span>
    </Themed.p>
  );
  const colorVariant = isVehicleActive
    ? {}
    : {
        backgroundColor: [null, "gray"],
        "::after": {
          content: "''",
          position: "absolute",
          background: (t) =>
            `repeating-linear-gradient(
              -45deg,
              ${t.rawColors.darken3},
              ${t.rawColors.darken3} 20px,
              ${t.rawColors.lighten3} 20px,
              ${t.rawColors.lighten3} 40px
            )`,
          left: 0,
          bottom: 0,
          top: 0,
          right: 0,
          zIndex: "overlay",
        },
      };

  return (
    <Link to={slug} variant="cards.vehicle.link">
      <Card variant="vehicle">
        <div sx={{ variant: "cards.vehicle.container" }}>
          {image && (
            <GatsbyImage
              image={image.childImageSharp.gatsbyImageData}
              alt={`Fahrzeug ${title}`}
              sx={{
                height: "100%",
                width: "100%",
              }}
            />
          )}
        </div>
        <div
          sx={{
            variant: "cards.vehicle.aside",
            ...colorVariant,
          }}
          className="hoverStyles"
        >
          <Themed.h4 sx={{ fontWeight: "xbold" }}>{title}</Themed.h4>
          <Themed.h5>{dispatcherName}</Themed.h5>
          {additionalInfo}
        </div>
      </Card>
    </Link>
  );
};

export default VehicleCard;
