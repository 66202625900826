/** @jsxImportSource theme-ui */
import React from "react";
import Layout from "../../components/Layout";
import Breadcrumbs from "../../components/Breadcrumbs";
import Seo from "../../components/Seo";
import { useStaticQuery, graphql } from "gatsby";
import Station from "../../modules/about/station/Station";
import Chronicle from "../../modules/about/chronicle/Chronicle";
import VehicleList from "../../modules/about/vehicles/VehicleList";
import Region from "../../components/Region";
import { Themed } from "@theme-ui/mdx";

const AboutPage = ({ location }) => {
  const { staticMap, events, vehicles } = useStaticQuery(graphql`
    query {
      staticMap {
        childFile {
          childImageSharp {
            gatsbyImageData(width: 1024, height: 1024)
          }
        }
      }
      events: allChronicleJson(sort: { fields: date }) {
        edges {
          node {
            date
            formatDate
            content
          }
        }
      }
      vehicles: allMarkdownRemark(
        filter: { frontmatter: { templateName: { eq: "about/vehicles" } } }
        sort: { fields: frontmatter___active___to, order: DESC }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              dispatcherName
              image {
                childImageSharp {
                  gatsbyImageData(height: 290)
                }
              }
              active {
                from
                to
              }
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <Seo
        title="Über uns"
        pathname={location.pathname}
        description="Erfahre alles rund um das Gerätehaus, die Fahrzeuge und die Geschichte der freiwilligen Feuerwehr Arborn."
      />

      <Layout>
        <Breadcrumbs names={["Über uns"]} location={location} />
        <Themed.h2>Über uns</Themed.h2>

        <Station staticMap={staticMap} />
        <VehicleList
          vehicles={vehicles.edges.map((x) => x.node)}
          sx={{ mt: 5 }}
        />
        <Region sx={{ mt: 5 }} />
        <Chronicle events={events.edges} sx={{ mt: 5 }} />
      </Layout>
    </>
  );
};

export default AboutPage;
