/** @jsxImportSource theme-ui */
import React from "react";
import VehicleCard from "./VehicleCard";
import { Themed } from "@theme-ui/mdx";

const VehicleList = ({ vehicles, ...props }) => {
  const activeVehicles = vehicles.filter((v) => !v.frontmatter.active.to);
  const historicVehicles = vehicles.filter((v) => v.frontmatter.active.to);

  return (
    <div {...props}>
      <Themed.h3>Fahrzeuge</Themed.h3>

      <div sx={{ display: "flex", flexDirection: ["column", "row"] }}>
        <div
          sx={{
            variant: "cards.responsiveSideHeader",
            mr: [null, 3],
            mb: [3, 0],
            borderLeft: (t) => [null, `4px solid ${t.colors.background2}`],
            borderBottom: (t) => [`4px solid ${t.colors.background2}`, `unset`],
            borderRadius: "small",
            color: "text",
          }}
        >
          aktiv
        </div>
        <div
          sx={{
            flex: "1 1",
            display: "grid",
            gridTemplateColumns:
              "repeat(auto-fit, minmax(min(410px, 100%), 1fr))",
            gridAutoRows: "290px",
            gridGap: 3,
          }}
        >
          {activeVehicles.map((v, idx) => (
            <VehicleCard vehicle={v} key={idx} />
          ))}
        </div>
      </div>

      <div sx={{ display: "flex", flexDirection: ["column", "row"], mt: 4 }}>
        <div
          sx={{
            variant: "cards.responsiveSideHeader",
            mr: [null, 3],
            mb: [3, 0],
            borderLeft: (t) => [null, `4px solid ${t.colors.gray}`],
            borderBottom: (t) => [`4px solid ${t.colors.gray}`, `unset`],
            borderRadius: "small",
            color: "text",
          }}
        >
          außer Dienst
        </div>
        <div
          sx={{
            flex: "1 1",
            display: "grid",
            gridTemplateColumns:
              "repeat(auto-fit, minmax(min(410px, 100%), 1fr))",
            gridAutoRows: "290px",
            gridGap: 3,
          }}
        >
          {historicVehicles.map((v, idx) => (
            <VehicleCard vehicle={v} key={idx} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default VehicleList;
