/** @jsxImportSource theme-ui */
import React from "react";
import useSiteMetadata from "../hooks/use-site-metadata";
import { StaticImage } from "gatsby-plugin-image";
import { Button, Container } from "theme-ui";
import { Themed } from "@theme-ui/mdx";
import Link from "../components/Link";

const Region = (props) => {
  const { neighbours } = useSiteMetadata();

  return (
    <div
      sx={{
        bg: "background2",
        color: "lightText",
        mx: [-3, -4],
        px: [3, 4],
        py: [5, null, 6],
        display: "grid",
        gridTemplateRows: "auto",
        gridTemplateColumns: ["auto", null, "auto 2fr 1fr"],
        gridTemplateAreas: [
          `"logo"
              "description"
              "aside"`,
          null,
          `"logo description aside"`,
        ],
        gridRowGap: 5,
        gridColumnGap: 4,
      }}
      {...props}
    >
      <div
        sx={{
          gridArea: "logo",
          display: "flex",
          flex: "1 1",
          placeItems: "center",
          placeContent: "center",
        }}
      >
        <StaticImage
          src="../img/wappen-greifenstein.png"
          alt="Wappen der Gemeinde Greifenstein"
          width={166}
          height={183}
          placeholder="none"
          layout="fixed"
        />
      </div>

      <Container
        sx={{
          gridArea: "description",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        <Themed.h3>Gemeinde Greifenstein</Themed.h3>
        <Themed.p>
          Die Gemeinde Greifenstein besteht aus zehn Ortsteilen, mit einer
          zusammengelegten Feuerwehr in Allendorf-Ulm und ansonsten für jeden
          Ortsteil jeweils eine eigene freiwillige Feuerwehr. Damit wird der
          Brandschutz der rund 67 km² großen Gemeinde durch neun Stützpunkte
          sichergestellt.
        </Themed.p>
        <div>
          <Button
            as={Link}
            variant="secondary"
            href="http://www.greifenstein.de/rathaus-politik/brandschutz.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Lerne mehr über den Brandschutz in Greifenstein &raquo;
          </Button>
        </div>
      </Container>

      <div
        sx={{
          gridArea: "aside",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        <Themed.h3>Nachbarwehren</Themed.h3>
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
            gridRowGap: 3,
            gridColumnGap: 5,
          }}
        >
          {neighbours.map(({ link, name }) => (
            <div key={name}>
              {link ? (
                <Link variant="light" href={link}>
                  <div
                    sx={{
                      borderBottom: (t) => `1px dashed ${t.colors.lighten6}`,
                    }}
                  >
                    {name} &raquo;
                  </div>
                </Link>
              ) : (
                <div
                  sx={{
                    borderBottom: (t) => `1px dashed ${t.colors.lighten6}`,
                  }}
                >
                  {name}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Region;
