/** @jsxImportSource theme-ui */
import React from "react";
import { getDate } from "../../../utils/dateTime";

export const Timeline = ({
  events,
  dotOutline,
  dotRadius = 6,
  lineWidth = 4,
  ...props
}) => (
  <section
    sx={{
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      position: "relative",
      fontSize: "90%",
      rowGap: 2,
    }}
    {...props}
  >
    {events.map(({ node: { date, formatDate, content } }, idx) => {
      const isEven = idx % 2 === 0;
      const align = isEven ? "left" : "right";

      return (
        <React.Fragment key={`timeline${idx}`}>
          {isEven && <Filler />}
          <TimelineEvent align={align}>
            <TimelineDot
              align={align}
              outline={lineWidth / 2}
              radius={dotRadius}
            />
            <div
              sx={{ color: "secondary", variant: "text.lead", lineHeight: 1.2 }}
            >
              {getDate(new Date(date), {
                day: formatDate === "fullDate",
                month: formatDate !== "onlyYear",
                year: true,
              })}
            </div>
            <div
              sx={{
                overflowWrap: "break-word",
                wordWrap: "break-word",
                wordBreak: "break-word",
                hyphens: "auto",
              }}
            >
              {content}
            </div>
          </TimelineEvent>
          {!isEven && <Filler />}
        </React.Fragment>
      );
    })}
    <TimelineLine width={lineWidth} />
  </section>
);

export const Filler = () => (
  <div
    sx={{
      position: "relative",
    }}
  />
);

export const TimelineDot = ({ align, radius, outline }) => (
  <div
    sx={{
      display: "flex",
      position: ["relative", "absolute"],
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      mt: `calc(-${outline}px + .5rem)`,
      justifyContent: ["center", align === "left" ? "flex-start" : "flex-end"],
      "::before": {
        content: "''",
        borderRadius: "50%",
        width: `${radius * 2}px`,
        height: `${radius * 2}px`,
        mx: `-${radius}px`,
        bg: "background",
        zIndex: 1,
        outline: (t) => `${outline}px solid ${t.colors.secondary}`,
        display: "flex",
      },
    }}
  />
);

export const TimelineLine = ({ width }) => (
  <div
    sx={{
      gridColumn: "-2",
      gridRow: "1/auto",
      width: `${width}px`,
      height: "100%",
      position: "absolute",
      ml: `-${Math.ceil(width / 2)}px`,
      zIndex: [-1, 0],
      background: (t) =>
        `repeating-linear-gradient(
              60deg,
              ${t.rawColors.secondary}80,
              ${t.rawColors.secondary}80 2px,
              ${t.rawColors.secondary}cc 2px,
              ${t.rawColors.secondary}cc 4px
            )`,
    }}
  />
);

export const TimelineEvent = ({ align = "left", children, ...props }) => (
  <div
    sx={{
      position: "relative",
      display: "flex",
      columnGap: 2,
      textAlign: ["center", align],
      pl: ["unset", align === "left" ? 3 : 0],
      pr: ["unset", align === "right" ? 3 : 0],
      flexDirection: ["column", align === "left" ? "row" : "row-reverse"],
      gridColumn: ["1/3", "auto"],
      alignItems: ["center", "unset"],
      bg: "background",
      my: 1,
    }}
    {...props}
  >
    {children}
  </div>
);

export default Timeline;
