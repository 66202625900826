/** @jsxImportSource theme-ui */
import React from "react";
import { Timeline } from "./Timeline";
import { BaseStyles } from "theme-ui";

export const TITLE = "Chronik";

const Chronicle = ({ events, location, ...props }) => (
  <BaseStyles>
    <div
      sx={{
        textAlign: "center",
        maxWidth: "wideContainer",
      }}
      {...props}
    >
      <h3>{TITLE}</h3>
      <Timeline events={events} />
    </div>
  </BaseStyles>
);

export default Chronicle;
